// extracted by mini-css-extract-plugin
export var animate = "index-module--animate--a87d2";
export var button = "index-module--button--d758f";
export var button3 = "index-module--button3--c916e";
export var captionDiv = "index-module--captionDiv--31a02";
export var cardContentDiv = "index-module--cardContentDiv--9a0b5";
export var cardDiv = "index-module--cardDiv--18a4d";
export var cardImgDiv = "index-module--cardImgDiv--5d0d0";
export var cardImgDiv1 = "index-module--cardImgDiv1--73148";
export var cardSlotDiv = "index-module--cardSlotDiv--2bc27";
export var cardSlotDiv1 = "index-module--cardSlotDiv1--bd7c3";
export var cardsRowDiv = "index-module--cardsRowDiv--a9529";
export var cardsSectionDiv = "index-module--cardsSectionDiv--7a09f";
export var catCardRowDiv = "index-module--catCardRowDiv--987f9";
export var catCardSlotDiv = "index-module--catCardSlotDiv--3c486";
export var categoriesSectionDiv = "index-module--categoriesSectionDiv--75dcf";
export var categoryCardsDiv = "index-module--categoryCardsDiv--2c19e";
export var ccsDiv = "index-module--ccsDiv--62940";
export var contactContentDiv = "index-module--contactContentDiv--da9c2";
export var contactDetailsDiv = "index-module--contactDetailsDiv--ffe00";
export var contactDetailsSignInDiv = "index-module--contactDetailsSignInDiv--f1c79";
export var contactFormDiv = "index-module--contactFormDiv--0a980";
export var contactFormInputsDiv = "index-module--contactFormInputsDiv--de705";
export var contactSectionDiv = "index-module--contactSectionDiv--9c725";
export var depositphotos349498906XL1Icon = "index-module--depositphotos349498906XL1Icon--020ac";
export var depositphotos349498906XL1Icon1 = "index-module--depositphotos349498906XL1Icon1--81df3";
export var detailsDiv = "index-module--detailsDiv--8df6d";
export var displayB = "index-module--displayB--9b47f";
export var displayDiv = "index-module--displayDiv--cbee1";
export var displayDiv1 = "index-module--displayDiv1--59d8c";
export var displayDiv2 = "index-module--displayDiv2--7aeca";
export var displayH2 = "index-module--displayH2--ab06b";
export var displayH21 = "index-module--displayH21--a16f1";
export var errorMsg = "index-module--errorMsg--dff35";
export var featureDiv = "index-module--featureDiv--2ef64";
export var featureLabelDiv = "index-module--featureLabelDiv--e5141";
export var featureSlotDiv = "index-module--featureSlotDiv--3f6ef";
export var featuresDiv = "index-module--featuresDiv--bb516";
export var featuresRowDiv = "index-module--featuresRowDiv--246ca";
export var formTitleDiv = "index-module--formTitleDiv--24be8";
export var fullNameDiv = "index-module--fullNameDiv--d5291";
export var heroActionsDiv = "index-module--heroActionsDiv--c6a85";
export var heroContentDiv = "index-module--heroContentDiv--ddfc4";
export var heroImgLDiv = "index-module--heroImgLDiv--bffcc";
export var heroSectionDiv = "index-module--heroSectionDiv--aab43";
export var heroTextDiv = "index-module--heroTextDiv--5d1af";
export var homeDiv = "index-module--homeDiv--dc51e";
export var iconBoxDiv = "index-module--iconBoxDiv--13ba0";
export var imgIcon = "index-module--imgIcon--05c44";
export var input = "index-module--input--2f049";
export var inputFieldWIthLabelDiv = "index-module--inputFieldWIthLabelDiv--a77d1";
export var inputLabelDiv = "index-module--inputLabelDiv--e48ea";
export var inputTextarea = "index-module--inputTextarea--92759";
export var label = "index-module--label--dc0e8";
export var label2 = "index-module--label2--33e8c";
export var labelDiv = "index-module--labelDiv--5d049";
export var labelDiv8 = "index-module--labelDiv8--b9428";
export var labelDiv9 = "index-module--labelDiv9--460dc";
export var medicineSyrupIcon = "index-module--medicineSyrupIcon--e409a";
export var pDiv = "index-module--pDiv--9ed6c";
export var patternDiv = "index-module--patternDiv--7b570";
export var patternIcon = "index-module--patternIcon--2c966";
export var sectionTitleDiv = "index-module--sectionTitleDiv--9b4c6";
export var signInBtnDiv = "index-module--signInBtnDiv--761ff";
export var slideInTop = "index-module--slide-in-top--e07da";
export var titleDiv = "index-module--titleDiv--6d0fa";
export var titleDiv1 = "index-module--titleDiv1--462e6";
export var udshSymbol1Icon = "index-module--udshSymbol1Icon--7b082";
export var visaIcon = "index-module--visaIcon--7ec62";
export var visibleDetailsDiv = "index-module--visibleDetailsDiv--37e00";
export var whySectionDiv = "index-module--whySectionDiv--6f768";